import React from 'react';
import Icon from '../../components/Icon/Icon';
import Image from '../../components/Image/Image';
import LanguageSelection from '../../components/LanguageSelection/LanguageSelection'
import { stripHtml } from '../../adapters/helpers/Utils';
import LoginNavigationBlock from '../../components/LoginNavigationBlock/LoginNavigationBlock';
import DOMPurify from 'isomorphic-dompurify';
import { optimizeImage } from './pagespeedHelper';
import { Constants } from './Constants';
export default class HierarchyUtils {

  static getLevel(hierarchyDoc) {
    if (!hierarchyDoc?.fields?.level) {
      return;
    }
    return hierarchyDoc.fields.level;
  }

  static renderHierarchy(hierarchy) {
    const destinations = hierarchy.fields.destinations;
    let title = hierarchy.fields.title;  
    return (
        <div>
          {title && this.renderHierarchyTitle(hierarchy, title)}
          {destinations &&
          <ul id={this.getHierarchyId(hierarchy)}>
            {
              destinations.map((d, index) => {
                d.imgVisible = hierarchy.imgVisible;
                return <li key={index}>{this.renderDestination(d)}</li>
             })
            }
          </ul>
          }
        </div>
    )
  }

  static renderDestination(dest, callback = null, icon = null, onClick, className = null, privacyLink) {
    const type = dest?.fields?.contentType;
    switch (type) {
      case 'pageLink':
        return this.renderPageLink(dest, icon, onClick, className);
      case 'hierarchy':
        dest.fields.level = 'child';
        return callback ? callback() : this.renderHierarchy(dest);
      case 'link':
        return this.renderLink(dest, icon, onClick, className, privacyLink);
      case 'languageSelectionBlock':
        return <LanguageSelection {...dest}/>;
      case 'loginNavigationBlock':
        return <LoginNavigationBlock document={dest}/>;
      default:
        return 'Unsupported Type : ' + type;
    }
  }

  static renderPageLink(pageLink, icon = null, onClick, className = null) {
    const link = pageLink?.fields?.pagePath?.fields?.slug;
    const imgVisible = pageLink.imgVisible;
    return this.renderLinkAndTitle(pageLink.fields, link, icon, onClick,className, imgVisible);
  }

  static renderLink(link, icon = null, onClick, className = null, privacyLinkObj = {}) {
    const url = link.fields?.url === Constants.PrivacyLinkPlaceholder ? (privacyLinkObj?.privacy || link?.fields?.fallBackUrl) : link?.fields?.url;
    const imgVisible = link.imgVisible;
    return this.renderLinkAndTitle(link.fields, url, icon, onClick, className, imgVisible);
  }

  static toFirstUpperCase(string, isMobile) {
    if(isMobile) {
      let toLoweCase = string && string.toLowerCase();
      return toLoweCase.charAt(0).toUpperCase() + toLoweCase.slice(1);
    } else {
      return string;
    }
  }

  static renderLinkAndTitle(link, url, icon = null, onClick, className = null, imgVisible = false) {    
    let classNames = '';
    if (link?.linkClassName) {
      classNames = link?.linkClassName
    }
    if (className) {
      classNames += ` ${className}`;
    }
    let currentPage = false
    if(className && className.indexOf('is-active') > -1){
      currentPage = true
    }
    return (
        <a href={url} data-action-detail={stripHtml(link.dataActionDetail ? link.dataActionDetail : link.title)}
          aria-label={stripHtml(link.dataActionDetail ? link.dataActionDetail : link.title)}
          onClick={onClick} target={link?.type === 'external' ? '_blank' : undefined}
          rel={link?.type === 'external' ? 'noopener noreferrer' : undefined} className={classNames}>
          {imgVisible ? '' : link.image && this.renderImage(link.image, link)}
          <span>
            { icon && icon.position === 'before' && <Icon {...icon} /> }
            {
              currentPage ?  
              <span aria-current={'page'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(link.title)}}></span> :
              <>
              <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(link.title)}}></span>
                {link.largeTitle && 
                  <span className="largeTitle">
                  <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(link.largeTitle)}}></span>
                  </span>
                }
                </>    
              }
            { icon && (icon.position === 'after' || icon.position === '') && <Icon {...icon} /> }
          </span>
        </a>
    )
  }

  static renderImage(image, link) {
    const handleMouseOver = (event, image) => {
      event.currentTarget.src = image;
    }
    const handleMouseOut = (event, image) => {
      event.currentTarget.src = image;
    }
    return (
      link?.isPopupImageNeed ?
        <div className='ob-mainAsset-wrapper'>
          <img className='ob-mainAsset-wrapper-img' src={optimizeImage(image?.fields?.asset?.fields?.file?.url)} aria-hidden={true} height={150} width={150} alt={image?.fields?.alternateText}
          onMouseOver={(event) => handleMouseOver(event, link?.hoverImage?.fields?.asset?.fields?.file?.url)}
          onMouseOut={(event) => handleMouseOut(event, image?.fields?.asset?.fields?.file?.url)} 
          />
        </div>
      :
      <Image noLazyLoad={true} image={image} ariaHidden={true} />
    )
  }

  static getHierarchyId(hierarchyDoc) {
    let id = hierarchyDoc.fields.name;
    id = id.replace(/ /g, '-');    
    return id;
  }

  static renderHierarchyTitle(hierarchy, title) {
    return (
        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}}></div>
    );
  }
}
